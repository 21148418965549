@import "styles/_variables.scss";

.title {
    text-align: center;
    margin-bottom: 20px;
}

.subtitle {
    text-align: center;
    margin-bottom: 56px;
}

.numbers {
    display: flex;
    justify-content: space-between;
    position: relative;
    @media(max-width: $mobilePoint) {
        flex-direction: column;
    }
    &__hr {
        margin: 0;
        border: none;
        border-left: 1px solid #D9D9D9;
        height: 100%;
        width: 1px;
        position: absolute;
        @media(max-width: $mobilePoint) {
            display: none;
        }
        &_first {
            left: 366px;

            @media(max-width: $tabletPoint) {
                left: 240px;
            }
        }

        &_second {
            right: 366px;

            @media(max-width: $tabletPoint) {
                right: 240px;
            }
        }
    }

    &__item {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 384px;
        @media(max-width: $mobilePoint) {
            width: 100%;
            margin-bottom: 32px;
            &:last-child{
                margin-bottom: 0;
            }
        }
        &__title {
            font-family: $fontFamily;
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 32px;
            color: $mainText;
        }
    }
}